.maindiv {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    text-align: left;
}


.rightdiv {
    width: 100%;
    padding: 10px;
}


.inputdiv {
    margin-top: -10px
}

.input {
    border:1px solid #8897a2;
    font-size: 15px;
    height: 38px;
    width: 100%;
    border-radius: 3px;
    padding-left: 5px;
    padding: 5px;
}

.star {
    color: #f76b6d;
    padding-left: 1px;
}

.spanName {
    font-size: 14px;
    color: #8897a2
}

.form {
    width: 90%;
    margin: auto;
    margin-left: 25px;
    line-height: 3.5
}
.subheading{
    font-size:16px;
    font-weight:bold;
    margin-left: 5px;
}
.accountIcon{
    font-size: 35px;
    color:teal;
    border-radius: 50%;
}
.icons{
    height:20px;
    width:20px;
    color:rgb(118,118,118);
    vertical-align: middle;
}
.leftdivSubheading{
    font-size: 13px;
    font-weight:700;
    color:rgb(92,89,96);
    margin-left:30px;
}
.leftsubunder{
    margin-left:60px
}
.rightmain{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    padding: 20px;
}