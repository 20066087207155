.container{
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;    
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px;
}
.leftContainer{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    width: 170px;
    color: white;
    text-align: center;
    padding: 15px;
    background-color: teal ;
}
.rightContainer{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.topRcontainer{
    color: rgb(99,178,156) ;
    font-weight: 700;
    font-size: large;
}
.middleRcontainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.middleRcontainer>div{
    display: flex;
}
.middleRcontainer>div>p{
    font-size: 10px;
    font-weight: 700;
}
.middleRcontainer>div>span{
    font-size: 10px;
}
.bottomRcontainer>p{
    padding: 4px;
    border-radius: 10px;
    width: fit-content;
    font-size: 8px;
    background-color: aquamarine;
    color: teal.300;
}
.joinButton{
    padding: 5px;
    color:white;
    font-weight: 500;
    border-radius: 5px;
    background-color: teal ;
}