.health-bar {
    width: 100%;
    height: 30px;
    background-color: #ddd;
    margin-top: 10px;
  }
  
  .health-bar-inner {
    height: 100%;
    background-color: #f39f03;
    transition: width 0.3s;
  }
  