.typing-game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
  }
  
  .text-container {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
    margin: 10px auto;
  }
  
  .falling-character {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .falling-character.inactive {
    opacity: 0;
    transform: translateY(100%);
  }
  /* Styling for the card-like container */
.game-settings-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  max-width: 500px; /* Set the maximum width of the card */
  margin: 0 auto; /* Center the card horizontally */
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.container{
  text-align: left;
}

/* Styling for the "Start Game" button */
.game-settings-card button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
}

/* Styling for the radio buttons and labels */
.game-settings-card input[type="radio"] {
  margin-right: 10px;
}

/* Styling for the selected radio button label */
.game-settings-card input[type="radio"]:checked + label {
  color: #4caf50;
  font-weight: bold;
}




/* Styling for the "Game Over" card */
.game-over-card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  position: fixed; /* Fixed position to cover the entire screen */
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100; /* Ensure it's above other content */
}

/* Styling for the "Restart" button */
.game-over-card button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 20px;
}

/* Keyframes for the star animation */
@keyframes starburst {
  0% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(200px, 200px) scale(0.1);
  }
}

/* Star elements */
.star {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffcc00; /* Star color */
  clip-path: polygon(50% 0%, 61.8% 38.2%, 100% 44.3%, 70.9% 70.9%, 76.4% 100%, 50% 82.4%, 23.6% 100%, 29.1% 70.9%, 0% 44.3%, 38.2% 38.2%);
  animation: starburst 2s ease-out;
  animation-fill-mode: forwards;
  transform-origin: 50% 50%;
}

/* Additional star colors and styles can be added as needed */
